import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plyo Pushups 4×4`}</p>
    <p>{`Shoulder Press 4×4\\@80% 1RM`}</p>
    <p>{`DB Rows 4×4`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`15-Cal Ski Erg`}</p>
    <p>{`12-Box Jump Overs (24/20)`}</p>
    <p>{`9-HSPU’s`}</p>
    <p>{`6-Renegade Rows (30/20’s)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday & Sunday, November 17th-18th, CrossFit the Ville will
be hosting a USAW Weightlifting Level 1 Coaching Certification Course!
 The Scope and Sequence of this course includes the teaching
progressions of the Snatch, Clean & Jerk, and all associated movements.
 Participants will gain knowledge and experience of programming of
training for both competitive weightlifters and for athletes using the
weightlifting movements for strength and power development as it applies
to their chosen sport.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Lead Instructor: Mark Cannella, President and Head Coach of Columbus
Weightlifting in Columbus, Ohio.  He has been part of the lifting
community as an athlete, coach, administrator, and official for over 29
years.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`This 2 day course is \\$499.  Those who complete this course will be
awarded their USAW Level 1 Coaching Certificate.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more information contact Daniel\\@crossfittheville.org or`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Mark Cannella at mark\\@columbusweightlifting.org.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`***`}</strong>{`The gym will be closed for this event.  Saturday is a
scheduled Rest Day but we will make up Sunday’s WOD the following week
by eliminating the next Rest Day to get back on track.`}{`*`}{`*`}{`***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Click the link below to register: `}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977"
      }}>{`https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      